// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '@ctrl/ngx-emoji-mart/picker';

.titleicon {
  position: absolute;
  width: 40px;
  left: 7px;
  bottom: 5px;
  text-align: center;
}

.alert-title {
  color: var(--pink);
}

.grade-chip {
  --background: var(--black);
  color: var(--yellow);
  padding: 0 2px;
  margin: 0 2px;
  font-size: 9px;
  min-height: 5px;
  height: 14px;

  ion-label {
    padding: 0 2px;
  }
}

.mini-chip {
  --background: var(--black);
  font-size: 9px;
  min-height: 5px;
  height: 14px;
  padding: 0 4px 0 3px;
  margin: 0 3px 0 0;

  ion-icon {
    font-size: 16px;
  }

  ion-label {
    padding: 0 2px 1px;
  }

}


ion-tab-bar {
  box-shadow: 0px -6px 15px rgb(30, 30, 30);
}

ion-toolbar {
  box-shadow: 0 6px 15px rgb(30, 30, 30);
}

ion-action-sheet.grade-chooser .action-sheet-button,
ion-action-sheet.grade-chooser .action-sheet-button.ion-focused {
  color: var(--yellow);
}

ion-action-sheet.grade-chooser .action-sheet-button .action-sheet-button-inner {
  text-align: center;
  justify-content: center;
}

ion-action-sheet.grade-chooser .action-sheet-title {
  color: var(--pink);
  text-align: center;
}

ion-input.username.ios .input-bottom .helper-text,
ion-input.username.ios .input-bottom .counter,
ion-input.username.md .input-bottom .helper-text,
ion-input.username.md .input-bottom .counter {
  color: var(--red);
}

@media (any-hover: hover) {
  ion-action-sheet.grade-chooser .action-sheet-button:hover {
    color: var(--pink);
  }
}

ion-action-sheet.grade-chooser {
  --background: var(--darkGrey);
}

ion-spinner {
  --color: var(--pink);
}

ion-button::part(native) {
  height: auto;
}

ion-footer {
  --ion-background-color: var(--darkGrey);
  background: var(--darkGrey);
}