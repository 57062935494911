:root {

  * {
    font-family: 'Roboto', 'FontAwesome', sans-serif;
  }

  $red: #e40602;
  $lightGreen: #06E54D;
  $darkGreen: #009931;

  $lightBlue: #68cad6;

  $darkGrey: #313230;
  $white: #fff;
  $black: #222222;
  $grey: #4444;
  $lightGrey: #6b6c6a;

  --pink: #ef4b89;
  --yellow: #ffe367;
  --darkGrey: #313230;
  --lightBlue: #68cad6;
  --lightGrey: #6b6c6a;
  --black: #222222;
  --white: #fff;
  --green: #5b9a5f;
  --red: #e4514b;
  --orange: #ff991d;

  /** primary **/
  --ion-color-primary: var(--pink);
  --ion-color-primary-contrast: var(--darkGrey);
  --ion-color-primary-contrast-rgb: var(--darkGrey);


  /** secondary **/
  --ion-color-secondary: var(--yellow);
  --ion-color-secondary-contrast: var(--darkGrey);
  --ion-color-secondary-contrast-rgb: var(--darkGrey);

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: var(--darkGrey);
  --ion-text-color: var(--yellow);
  --ion-toolbar-background: var(--yellow);
  --ion-toolbar-color: var(--darkGrey);
  --ion-tab-bar-background: var(--yellow);
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;

  --ion-app-width: 450px;

  ion-app {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    // TODO max-width: var(--ion-app-width);
    cursor: pointer;
  }

  .modal-wrapper {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ion-modal {
    --width: 100%;
    --height: 100%;
  }

  ion-alert, .bb-alert {
    --background: var(--darkGrey);
    color: var(--yellow);

    .cancel-button {
      color: var(--yellow);
    }

    .yes-button {
      color: var(--pink);
      font-size: 20px;
    }

    .alert-title {
      color: var(--yellow);
      font-size: 22px;
      font-weight: 500;
    }

    .alert-message, .alert-radio-label, .alert-checkbox-label {
      color: var(--yellow);
      font-size: 13px;
    }

    .alert-button {
      height: 50px;
    }

    .secondary {
      color: var(--yellow);
    }

    .primary {
      color: var(--pink);
    }

    [aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
      color: var(--yellow);
    }
  }

  ion-card {
    --background: var(--white);
    --color: var(--darkGrey);
  }

  ion-card-title {
    --color: var(--darkGrey);
  }

  ion-list-header {
    ion-label {
      color: var(--yellow);
    }
  }

  ion-item {
    --inner-border-width: 0;
  }

  ion-segment-button {
    font-size: 10px;
    height: auto;
    --margin-start: 0px;
    --margin-end: 0px;
    --padding-end: 0;
    --padding-start: 0px;
    --background: var(--yellow);
    --background-checked: var(--yellow);
  }

  .button-large {
    height: 40px;
  }

  .button-small {
    font-size: 14px;
  }

  ion-tab-button {
    --color: var(--lightGrey);
    --color-selected: var(--pink);
  }

  ion-loading {
    --background: var(--darkGrey);
    --spinner-color: var(--yellow);
    --color: var(--yellow);
    color: var(--yellow);
  }

  .md {

    ion-toolbar {
      height: auto;
    }

    ion-item {
      ion-label {
        /** Weird CSS bug causing ion-label to overlap ion-input when wrapped in ion-item **/
        //padding-bottom: 15px;
      }
    }

  }

  .ios {

    ion-app {
      max-width: 100%;
    }

    ion-content {
      pointer-events: auto;
    }

    ion-header, ion-toolbar, ion-segment, ion-buttons {
      max-height: calc(120px - constant(safe-area-inset-top));
      //height: calc(120px - 20px);
    }
  }

  body {
    margin-top: constant(safe-area-inset-top) px;
    margin-bottom: constant(ion-safe-area-bottom) px;
    //margin-top: env(safe-area-inset-top);
  }

  .input-field {
    margin-bottom: 10px;

    ion-input, ion-textarea {
      --background: var(--white);
      --color: var(--pink);
      --placeholder-color: var(--pink);
      --placeholder-opacity: .8;
      --padding-start: 10px;
      font-size: 18px;
    }

    ion-input.md, ion-textarea.md {
      --padding-top: 10px;
      --padding-bottom: 10px;
      -webkit-padding-start: 0;

    }

    ion-input .input-wrapper, ion-textarea .input-wrapper {
      --padding-start: 10px;
    }

    ion-input .helper-text {
      color: var(--ion-color-step-700, var(--red));
    }
  }

  .has-focus.textarea-fill-outline.textarea-label-placement-floating.sc-ion-textarea-md-h .label-text-wrapper.sc-ion-textarea-md, .has-value.textarea-fill-outline.textarea-label-placement-floating.sc-ion-textarea-md-h .label-text-wrapper.sc-ion-textarea-md, .textarea-fill-outline.textarea-label-placement-stacked.sc-ion-textarea-md-h .label-text-wrapper.sc-ion-textarea-md {
    margin-top: 15px;
    padding-bottom: 10px;
  }

  .textarea-bottom.sc-ion-textarea-ios .helper-text.sc-ion-textarea-ios,
  .textarea-bottom.sc-ion-textarea-md .helper-text.sc-ion-textarea-md {
    color: var(--yellow);
  }

  .max-width {
    max-width: 800px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    body {
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../assets/imgs/schuppen.jpg) no-repeat center/cover fixed;
    }

    .max-width {
      width: 100%;
    }
  }
}
